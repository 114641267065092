import React, { Suspense, useEffect, useReducer, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "./components/context/AppContext";
import { GetAPI } from "./components/ultities/API.js";

// import { messaging, getToken, onMessage } from './components/ultities/firebase/fb_messaging.js';




const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
// Pages

const Login = React.lazy(() => import("./views/Pages/Login.js"));
const ResetPasswordCoach = React.lazy(() => import("./views/Pages/ResetPasswordCoach.js"));
const ResetPasswordUser = React.lazy(() => import("./views/Pages/ResetPasswordUser.js"));
const Signup = React.lazy(() => import("./views/Pages/Signup.js"));
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/ForgotPassword.js")
);
const Page404 = React.lazy(() => import("./views/Pages/Page404.js"));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {

  // const location = useLocation()
  //firebase notification 
  // useEffect(() => {
  //   const requestPermission = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === 'granted') {
  //         getToken(messaging, { vapidKey: 'BPUxvOrLeDqLwIrarmDDhkGD2jI6-61ACYZmuiryFwkjAKIJynpx0vvUpfw-KitBSL8WxMcVwPk4aY6wTMVKy50' }).then((currentToken) => {
  //           if (currentToken) {
  //             // Send the token to your server and update the UI if necessary
  //             console.log(currentToken, "token generated");
  //           } else {
  //             // Show permission request UI
  //             console.log('No registration token available. Request permission to generate one.');
  //           }
  //         }).catch((err) => {
  //           console.log('An error occurred while retrieving token. ', err);
  //         });
  //       } else {
  //         console.log('Notification permission not granted');
  //       }
  //     } catch (error) {
  //       console.log('Error getting notification permission or token:', error);
  //     }
  //   };

  //   requestPermission();

  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     const notificationTitle = payload.notification.title;
  //     const notificationOptions = {
  //       body: payload.notification.body,
  //       icon: '/firebase-logo.png'
  //     };

  //     new Notification(notificationTitle, notificationOptions);
  //   });

  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('./firebase-messaging-sw.js')
  //       .then((registration) => {
  //         // messaging.useServiceWorker(registration);
  //         console.log('Registration successful, scope is:', registration.scope);
  //       })
  //       .catch((err) => {
  //         console.log('Service worker registration failed, error:', err);
  //       });
  //   }
  // }, []);



  var li = localStorage.getItem("loggedIn");
  var logged = JSON.parse(localStorage.getItem("user_details"));
  // var token = JSON.parse(localStorage.getItem('token')) || '';
  useEffect(() => {
    setUser({
      type: "onload",
      loggedin: li != undefined && li != "false",
      loginfo: logged,
      // token: token
    });
  }, []);


  const initialprops = {
    loggedin: false,
    loginfo: {},
    token: "",
    profile_picture: '',
    messageCount: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "login":
        return {
          ...state,
          loggedin: true,
          loginfo: action.loginfo,
          // routes: nav_roues,
        };
      case "logout":
        return { loggedin: false };
      case "onload":
        return {
          ...state,
          loggedin: action.loggedin,
          loginfo: action.loginfo,
          // routes: nav_roues,
        };
      case "messageCount":
        return {
          ...state,
          messageCount: action.messageCount,
        };
      default:
        return state;
    }
  };
  // features - 'settings', 'chat','share','users'
  // var role = 1;
  const [user, dispatch] = useReducer(reducer, initialprops);

  const setUser = (obj) => {
    dispatch(obj);
  };
  const [call_start, setCall_start] = useState(false); //Tele-consultation call start and end control
  const [online_teleData, setOnline_teleData] = useState({});
  // useEffect(() => {
  //   window.addEventListener("beforeunload", (e) => {
  //     localStorage.removeItem("loggedIn");
  //   });
  // }, []);
  useEffect(() => {
    if (user?.loggedin) {
      fetchMsgCount()
    }
  }, [user?.loggedin])

  const fetchMsgCount = () => {
    const Url = "/coach_dashboard_count";
    GetAPI(Url, (res) => {
      if (res?.status === 200) {
        setUser({ type: "messageCount", messageCount: res?.data[0]?.message_count })
      }
    });
  }



  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        call_start,
        setCall_start,
        online_teleData,
        setOnline_teleData,
        dispatch
      }}
    >
      <Router>
        <Suspense fallback={loading}>
          <Routes>
            {!user.loggedin ? (
              <>
                {/* <Route
                  exact
                  path='/vet'
                  name='Landing page'
                  element={<LandingPage />}
                /> */}
                {/* <Route
                  path='/vet/cliniclogin'
                  name='Clinic Login'
                  element={<ClinicLogin />}
                /> */}
                <Route path="/login" name="Login Page" element={<Login />} />
                <Route
                  exact
                  path="/signup"
                  name="Register Page"
                  element={<Signup />}
                />

                <Route
                  exact
                  path="/forgotpassword"
                  name="Forgot Password"
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path="/resetpasswordcoach"
                  name="Reset Password"
                  element={<ResetPasswordCoach />}
                />
                <Route
                  exact
                  path="/resetpassworduser"
                  name="Reset Password"
                  element={<ResetPasswordUser />}
                />
                {/* <Route
                  path='/resetpassword'
                  name='Reset Password'
                  element={<ResetPassword />}
                /> */}
                <Route
                  path="/*"
                  element={
                    <>
                      <Login />
                      <Navigate to="/" replace />
                    </>
                  }
                />
                <Route path="/*" name="Home" element={<DefaultLayout />} />
              </>
            ) : (
              <>
                <Route
                  exact
                  path="/resetpasswordcoach"
                  name="Reset Password"
                  element={<ResetPasswordCoach />}
                />
                <Route
                  exact
                  path="/resetpassworduser"
                  name="Reset Password"
                  element={<ResetPasswordUser />}
                />
                <Route path="/*" name="Home" element={<DefaultLayout />} />
              </>
            )}
            <Route path="*" element={<Page404 />} />
            {/* <Route exact path='/500' name='Page 500' element={<Page500 />} /> */}
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer autoClose={5000} />
    </AppContext.Provider>
  );
}
export default App;